export function localGet (key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
    return value
  }
}

export function localSet (key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function localRemove (key) {
  window.localStorage.removeItem(key)
}

// 判断内容是否含有表情字符，现有数据库不支持。
export function hasEmoji (str = '') {
  const reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
  return str.match(reg) && str.match(reg).length
}

//HTML转义
export function HTMLEncode(html) {
  var temp = document.createElement("div");
  (temp.textContent != null) ? (temp.textContent = html) : (temp.innerText = html);
  var output = temp.innerHTML;
  temp = null;
  return output;
}

//HTML反转义
export function HTMLDecode(text) {
  var temp = document.createElement("div");
  temp.innerHTML = text;
  var output = temp.innerText;
  temp = null;
  return output;
}

//对象转义
export function unescapeHtml4(json) {
  // if (json == "") {
  //   return null
  // }
  if (typeof (json) == "string") {
    return HTMLDecode(json)
  }
  for (let key in json) {
    if (json[key], Array.isArray(json[key]) == false) {
      // 删除空项
      json[key] = unescapeHtml4(json[key])
      // if (json[key] == null) {
      //   delete json[key]
      // }
    } else {
      for (let key2 in json[key]) {
        // 删除空项
        json[key][key2] = unescapeHtml4(json[key][key2])
        // if (json[key][key2] == null && json[key].length == 1) {
        //   delete json[key]
        // }
      }
    }
  }
  return json
}


// 单张图片上传
export const uploadImgServer = 'http://backend-api-02.newbee.ltd/manage-api/v1/upload/file'
// 多张图片上传
export const uploadImgsServer = 'http://backend-api-02.newbee.ltd/manage-api/v1/upload/files'
