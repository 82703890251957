import {createApp} from 'vue'
import App from './App.vue'
import {router} from './router'

import ElementPlus from 'element-plus';
import {ElMessage} from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import 'element-plus/theme-chalk/index.css';
import {hasPermission} from '@/utils/permission.js'
import VueCookies from 'vue-cookies'
import './assets/css/main.css';
import './assets/js/main';
import axios from "@/utils/axios";
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

//时间格式化
// 定义全局时间戳过滤器
// Vue.filter('formatDate', function(value, reg) {
//   return Moment(value).format(reg)
// })


// 判断是否登陆
if (window.location.pathname != "/Login") {

    if (VueCookies.get("token")) {

        // 获取字典数据
        axios.get('/Dict/List').then((data) => {
            window.global.dict = data.data

            // 获取登陆信息
            axios.get('/User/InfoByToken?token=' + VueCookies.get("token")).then((data) => {
                window.global.userinfo = data.data

                // 创建vue
                var app = createApp(App).use(router).use(ElementPlus, {locale: zhCn})
                Object.keys(ElementPlusIconsVue).forEach((key) => {
                    app.component(key, ElementPlusIconsVue[key]);//JS中用这行
                });

                router.isReady().then(() => {
                    app.mount('#app')
                })
                // 权限处理
                app.directive('hasPermission', hasPermission)
            }).catch(error => {
                window.location.href = "/Login"
            })
        }).catch(error => {
            ElMessage.error('获取数据失败，请检查后台运行转台！')
        })
    } else {
        window.location.href = "/Login"
    }
}else{
    // 创建vue
    var app = createApp(App).use(router).use(ElementPlus, {locale: zhCn})
    app.component("User", ElementPlusIconsVue["User"]);
    app.component("Lock", ElementPlusIconsVue["Lock"]);
    app.component("Key", ElementPlusIconsVue["Key"]);
    app.mount('#app')

}
