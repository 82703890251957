import {localGet} from "@/utils/index";

export const hasPermission = {

    // 删除空的菜单
    mounted(el, binding, vnode) {

        if (el.className == "el-menu-item"){
            var len = 0
            el.parentElement.querySelectorAll("li").forEach(function(li) {
                if (li.style.display == ""){
                    len ++
                }
            });
            if (len == 0){
                el.parentElement.parentElement.remove()
            }
        }
    },

    beforeMount(el, binding, vnode) {
        // 最高管理员
        if (window.global.userinfo.role_id == 23691354597501 || !binding.value){
            return true
        }
        var authlist = []
        var auth = localGet("YXV0aG9yaXR5")

        if (auth) {
            authlist = JSON.parse(window.atob(auth))
        } else {
            authlist = []
        }
        const value = binding.value

        let flag = true
        if (!authlist.includes(value)) {
            flag = false
        }

        if (!flag) {
            el.style.display = 'none'
        }
    }
}
