import axios from 'axios'
import {ElMessage} from 'element-plus'
import VueCookies from 'vue-cookies'
import {unescapeHtml4} from './index'
import JSONbig from 'json-bigint'//解决超过 16 位数字精度丢失问题

let RequestUrls = []
function removeRequestUrl(url) {
    var index = RequestUrls.indexOf(url)
    if (index >= 0){
        RequestUrls.splice(index, 1)
    }
}

// 这边由于后端没有区分测试和正式，姑且都写成一个接口。
axios.defaults.baseURL = window.global.config.baseUrl + "/Api"
// 携带 cookie，对目前的项目没有什么作用，因为我们是 token 鉴权
axios.defaults.withCredentials = true
// 请求头，headers 信息
// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers['token'] = VueCookies.get("token")
// 默认 post 请求，使用 application/json 形式
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.timeout = 60000

// 下载表格
axios.exportExecl = function (url, filename, config){
    axios.get(url, config).then(res => {
        let blob = new Blob([res], {
            type: 'application/vnd.ms-excel;charset=utf-8'
        });
        let objectUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = objectUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    })
}

// 请求拦截器，内部根据返回值，重新组装，统一管理。
axios.interceptors.response.use(
    res => {
        if (res.config.method == "post") {
            removeRequestUrl(res.config.url)
        }
        if (res.data instanceof Blob) {
            return Promise.resolve(res.data)
        }
        if (res.data.code > 0) {
            if (res.data.msg) ElMessage.error(res.data.msg)
            return Promise.reject(res.data)
        } else {
            res.data = unescapeHtml4(res.data)
        }
        return res.data
    },
    error => {
        if (error.config && error.config.method == "post") removeRequestUrl(error.config.url)
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 403:
                    if (error.response.data.code === 34032 || error.response.data.code === 34033 || error.response.data.code === 34034 || error.response.data.code === 34039) {
                        VueCookies.set("token", "")
                        // setTimeout(() => {
                        window.location.href = "/Login"
                        // }, 1500)
                    }else{
                        if (typeof error.response.data.data == "string"){
                            ElMessage.error("没有 \""+ error.response.data.data +"\" 权限，请联系管理员！")
                        }else{
                            ElMessage.error(error.response.data.msg)
                            return
                        }
                    }
                    break;
                case 404:
                    ElMessage.error("接口不存在 " + error.response.config.url)
                    break;
                default:
                    ElMessage.error("接口出现错误")
            }
        }
        return Promise.reject(error.response);
    }
)
axios.interceptors.request.use(function (config) {

        // 遍历检查参数类型
        if (config.params) {
            for (let key in config.params) {
                // 统一处理创建时间查询
                if (key == "createdDate"){
                    config.params["startDate"] = config.params["createdDate"][0]+" 00:00:00"
                    config.params["stopDate"] = config.params["createdDate"][1]+" 23:59:59"
                    delete config.params[key]
                }
                // 统一处理数组类型查询
                if (typeof config.params[key] == 'object') {
                    config.params[key] = config.params[key].join(",")
                }
            }
        }
        if (config.method == "post"){
            if (RequestUrls.indexOf(config.url) > -1) {
                console.log("相同的请求还没有返回，请稍后再试！")
                // ElMessage.error("相同的请求还没有返回，请稍后再试！")
                return false
            }
            RequestUrls.push(config.url)
        }
        return config
    },
    error => {
        return Promise.reject(error)
    })

axios.defaults.transformResponse = [
    //解决超过 16 位数字精度丢失问题
    function(data) {
        return typeof data == "object" ? data: JSON.parse(JSON.stringify(JSONbig.parse(data)))
    }
]
export default axios