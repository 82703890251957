import { createRouter, createWebHistory } from 'vue-router'
var Console = () => import('./Console.vue')

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/Welcome'
        },
        {
            path: '/User/RoleAuthority/List',
            component: Console,
            children: [
                {
                    path: '',
                    component: () => import('./models/User/RoleAuthority/List.vue'),
                }
            ]
        },
        {
            path: '/Login',
            component: () => import('./models/Login.vue'),
        },
        {
            path: '/Welcome',
            component: Console,
            children: [
                {
                    path: '',
                    component: () => import('./models/Welcome.vue'),
                }
            ]
        },
        // 下面为自动生成
        {
            path: '/User/WXAppletUser',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/WXAppletUser/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/WXAppletUser/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/WXAppletUser/Info.vue'),
                }
            ]
        },
        
		{
            path: '/System/SystemInfo',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/System/SystemInfo/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/System/SystemInfo/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/System/SystemInfo/Info.vue'),
                }
            ]
        },
		{
            path: '/System/SystemErrorLog',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/System/SystemErrorLog/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/System/SystemErrorLog/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/System/SystemErrorLog/Info.vue'),
                }
            ]
        },
		{
            path: '/User/Center',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/Center/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/Center/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/Center/Info.vue'),
                }
            ]
        },
		{
            path: '/User/Logs',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/Logs/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/Logs/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/Logs/Info.vue'),
                }
            ]
        },
		{
            path: '/User/Role',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/Role/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/Role/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/Role/Info.vue'),
                }
            ]
        },
		{
            path: '/User/User',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/User/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/User/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/User/Info.vue'),
                }
            ]
        },
        {
            path: '/FinancialManagement/AnnualFinancialStatistics',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/FinancialManagement/AnnualFinancialStatistics/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/FinancialManagement/AnnualFinancialStatistics/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/FinancialManagement/AnnualFinancialStatistics/Info.vue'),
                },
				{
				    path: 'Water',
				    component: () => import('./models/FinancialManagement/AnnualFinancialStatistics/Water.vue'),
				}
            ]
        },
		{
            path: '/FinancialManagement/MonthlyFinancialStatistics',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/FinancialManagement/MonthlyFinancialStatistics/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/FinancialManagement/MonthlyFinancialStatistics/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/FinancialManagement/MonthlyFinancialStatistics/Info.vue'),
                },
				{
				    path: 'Water',
				    component: () => import('./models/FinancialManagement/MonthlyFinancialStatistics/Water.vue'),
				}
            ]
        },
		{
            path: '/FinancialManagement/DailyFinancialStatistics',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/FinancialManagement/DailyFinancialStatistics/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/FinancialManagement/DailyFinancialStatistics/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/FinancialManagement/DailyFinancialStatistics/Info.vue'),
                },
				{
				    path: 'Water',
				    component: () => import('./models/FinancialManagement/DailyFinancialStatistics/Water.vue'),
				}
            ]
        },
		{
            path: '/FinancialManagement/AgreementManagemena',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/FinancialManagement/AgreementManagemena/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/FinancialManagement/AgreementManagemena/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/FinancialManagement/AgreementManagemena/Info.vue'),
                }
            ]
        },
		{
            path: '/FinancialManagement/FinancialStatement',
            component: Console,
            children: [
                {
                    path: 'List', 
                    component: () => import('./models/FinancialManagement/FinancialStatement/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/FinancialManagement/FinancialStatement/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/FinancialManagement/FinancialStatement/Info.vue'),
                }
            ]
        },
		{
            path: '/OrderService/UmbOrder',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/OrderService/UmbOrder/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/OrderService/UmbOrder/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/OrderService/UmbOrder/Info.vue'),
                }
            ]
        },
		{
		            path: '/UmbService/TypePricing',
		            component: Console,
		            children: [
		                {
		                    path: 'List',
		                    component: () => import('./models/UmbService/TypePricing/List.vue'),
		                },
		                {
		                    path: 'Form',
		                    component: () => import('./models/UmbService/TypePricing/Form.vue'),
		                },
		                {
		                    path: 'Info',
		                    component: () => import('./models/UmbService/TypePricing/Info.vue'),
		                }
		            ]
		        },
		{
            path: '/UmbService/RegionStaff',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/UmbService/RegionStaff/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/UmbService/RegionStaff/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/UmbService/RegionStaff/Info.vue'),
                }
            ]
        },
		{
            path: '/UmbService/UmbPricing',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/UmbService/UmbPricing/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/UmbService/UmbPricing/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/UmbService/UmbPricing/Info.vue'),
                }
            ]
        },
		{
            path: '/UmbService/UmbLocation',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/UmbService/UmbLocation/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/UmbService/UmbLocation/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/UmbService/UmbLocation/Info.vue'),
                }
            ]
        },
		{
            path: '/UmbService/Region',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/UmbService/Region/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/UmbService/Region/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/UmbService/Region/Info.vue'),
                }
            ]
        },
    ]
})